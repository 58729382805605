import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";

/**
 * Bootstrap Stimulus.js Application and Hot Module Reloading
 *
 * Source: https://discourse.stimulusjs.org/t/duplicate-controller-instantiation-when-using-hot-module-replacement/159
 **/
let application: any = null;

if (module.hot) {
  module.hot.accept(); // tell hot module reloader to accept updates

  if (module.hot.data) {
    application = module.hot.data.application; // re-use old application if one was passed after update
  }
}

if (application === null) {
  application = Application.start(); // if no application so far, start it
}

if (module.hot) {
  module.hot.dispose((data) => {
    data.application = application; // on disposal of the old version (before reload), pass the old application to the new version
  });
}

const context = require.context(
  "../property_manager_controllers",
  true,
  /\.js$/
);
application.load(definitionsFromContext(context));

const sharedContext = require.context("../shared_controllers", true, /\.js$/);
application.load(definitionsFromContext(sharedContext));
